app = {
	settings:{
		//Device Detection
		viewport: $(window).width(),
		isPhone : $('html').hasClass('mobile'),
		isTablet : $('html').hasClass('tablet'),
		isDesk : $('html').hasClass('desktop'),
		
		// Scrolling
		currentSec: '#',
		
		// Menu
		menu_isOpen : false,
		
		//Podge content APi
		// api_key :'/key/ypgv303w60c4176m0f2hzu0whmk9ylufzhst777o',
		api_key : '/key/ibaac0tbk3e4wbl98pycgfox2zqg9ldtx6w9c',
		//podgeURL: 'http://api.tumblr.com/v2/blog/whatifmovie.com/posts',
		podgeURL: 'http://cms.podgeevents.com/api2/'
	},
	//INNIT FUNCTIONS
	init:function(){

		app.getAttendes();
		
		//Call bind events
		app.bindEvents();
		
		// Init scroll animations
		app.scrollMagic();
	},
	
	//EVENT BINDINGS
	bindEvents:function(){
		
		$('#js-menuToggle').click(function(e){
			e.preventDefault();
			app.menuToggle();
		});
		
		$('.order__tryagain').click(function() {
			app.tryagainHandler();
		});
		
		$('.js-legend-link').click(function(e){
			e.preventDefault();
			app.legendsQuotes($(this));
		});
		
		$('a.js-scroll-link').click(function(e){
				//e.preventDefault();
				app.scrollTo($(this).attr('href'));
		});
		
		$('a#scrollDown-link').click(function(e){
				//e.preventDefault();
				app.scrollTo($(this).attr('href'));
		});
	},
	
	//FUNCTIONS
	
	scrollTo:function(target) { //alert('scroll fired to '+target);
		
		app.settings.currentSec = target;
			
			//Check if in buger menu mode
			if (app.settings.viewport < 992 && app.settings.menu_isOpen) {
				app.menuToggle();//close the menu
			}
		
			
			$('html, body').animate({scrollTop: $(target).offset().top -70 }, 2000);
		
		//alert('scrolling');
	},
	
	menuToggle:function() {
		if ( app.settings.menu_isOpen ) {
			$('#js-menuMain').removeClass('is-open');
			$('#js-menuToggle').removeClass('is-open');
			$('body').removeClass('is-locked');
			app.settings.menu_isOpen = false;
		} else {
			$('#js-menuMain').addClass('is-open');
			$('#js-menuToggle').addClass('is-open');
			$('body').addClass('is-locked');
			app.settings.menu_isOpen = true;
		}
	},
	
	legendsQuotes: function(quotee) {

		var currentQuote = $(quotee).next('blockquote').clone();

		// console.log(currentQuote);
		// console.log(currentQuote[0].childNodes[1]);

		// .clone() needed to make copy of DOM element because .html() will otherwise move it from its previous DOM position.
		var currentPerson = $(quotee).next('blockquote').find('.con-legendPod__header').clone();
		var currentText = $(quotee).next('blockquote').find('.con-legendPod__quotetext').clone();

		// var currentPerson = currentQuote[0].childNodes[1].clone();
		// var currentText = currentQuote[0].childNodes[3].clone(); //mixing purejs and jquery NO NO

		// console.log(currentQuote);
		// console.log(currentPerson);
		// console.log(currentText);


		$('.js-legend-link').removeClass('is-active');
		$(quotee).addClass('is-active'); //marks the selection highlight
		
		$('.js-big-quote').html(currentQuote);
		// $('#js-big-quote_r').html(currentText);
		// $('#js-big-quote_l').html(currentPerson);
	},

//////////////////////////////////////////////
//! Players Carosel
//////////////////////////////////////////////

	slickUse:function(){

		$('#js-players-slider').slick({
			infinite:true,
			slidesToShow:1,
			slidesToScroll:1,
				centerMode: false,
			initialSlide:0,
			centerPadding:'5%',
			mobileFirst:true,
			lazyLoad: 'progressive',
			prevArrow: $('#js-player-left'),
			nextArrow: $('#js-player-right'),
			responsive: [
				{
					breakpoint: 750,
					settings: {
						slidesToShow: 2,
						slidesToScroll:2,
						initialSlide:0,
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						slidesToScroll:3,
						initialSlide:0,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll:3,
						initialSlide:0,
					}
				},
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 4,
						slidesToScroll:4,
						initialSlide:0,
					}
				},
			]
		});
		//console.log("slick initialised!"); 
	},

	getAttendes:function(){
		// call api as many times as there is tags.length
		var getURL = app.settings.podgeURL + 'get-members' +app.settings.api_key;

		app.getData(getURL, function(data){
			con = data;
			// console.log(data);
			var regex = new RegExp(/<!\[CDATA\[(.*)]]>/);
			// turn into decent JSON? 
			// console.log(con.getElementsByTagName("result"));
			members = [];
			for (var i = 0; i < con.getElementsByTagName("First_Name").length; i++) {
				var object = {};
				object.id = i;
				object.firstName = con.getElementsByTagName("First_Name")[i].childNodes[0].textContent;
				
				object.lastName = con.getElementsByTagName("Last_Name")[i].childNodes[0].textContent;
				object.twitter = con.getElementsByTagName("Twitter")[i].childNodes[0].textContent;
				object.email = con.getElementsByTagName("Email")[i].childNodes[0].textContent;
				object.job = con.getElementsByTagName("Job_Title")[i].childNodes[0].textContent;
				object.company = con.getElementsByTagName("Company_Name")[i].childNodes[0].textContent;

				// check if there exists a photo on the server - sometimes guests don't upload photo (or photo fails to upload)
				if(con.getElementsByTagName("Photo")[i].childNodes[0].textContent === ''){
					console.log(object.firstName + ' ' + object.lastName);
					object.photo = 'src="img/placeholder_photo.jpg"'
				} else {
					object.photo = 'data-lazy="http://cms.podgeevents.com/api/getphoto/key/wlzkl4nyvxem7rviw67yu0sw84fq9mpzvwf2ixv0/dir/150/photo/'
					object.photo += con.getElementsByTagName("Photo")[i].childNodes[0].textContent;
				}
				
				object.status = con.getElementsByTagName("Status")[i].childNodes[0].textContent;

				members.push(object);
			};

			app.settings.members = members;

			app.settings.total = members.length;
			app.settings.firstSet = members.length;
			app.settings.leftOver = app.settings.total - app.settings.firstSet;
			app.settings.curSet = app.settings.firstSet;
			app.settings.panelCount = 0;

			app.makeCards(members);

		});

	},

	makeCards:function(members){

		var curCardBkg = 0;
		var imgAvailable;

		for (var i = 0; i < app.settings.curSet; i++) {

			// console.log('http://cms.podgeevents.com/api/getphoto/key/wlzkl4nyvxem7rviw67yu0sw84fq9mpzvwf2ixv0/dir/150/photo/' + members[i].photo + '>');
			if(curCardBkg > 6) {
				curCardBkg = 1
			} else {
				curCardBkg++
			}
			// console.log(curCardBkg);

			$('#js-players-slider').append(
				'<div class="con-player">'
				//+'<div class="con-textBox-top">'
				//+'<svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 376 52"> <style type="text/css"> .playertextboxtop{fill:#FF681F;} </style> <polygon class="playertextboxtop" points="188,1.3 0,52 376,52 "/> </svg>'
				//+'</div>'
				+'<div class="con-player-ratiowrapper">'
				+'<div class="con-player-holder card'+ curCardBkg +'">'
					// + '<div class="con-player__portrait"><img data-lazy="http://cms.podgeevents.com/api/getphoto/key/wlzkl4nyvxem7rviw67yu0sw84fq9mpzvwf2ixv0/dir/150/photo/' + members[i].photo + '"></div>'
					+ '<div class="con-player__portrait"><img ' + members[i].photo + '"></div>'
					+ '<div class="con-player__name-wrapper"><h4 class="con-player__name">' + members[i].firstName + ' ' + members[i].lastName + '</h4></div>'
					+ '<h5 class="con-player__role">' + members[i].job + '</h5>' 
					+ '<h6  class="con-player__company">'+ members[i].company + '</h6>'
				+'</div>'
				//+'<div class="con-textBox-bottom">'
				//+'<svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 376 52"> <style type="text/css"> .playertextboxbottom{fill:#FF681F;} </style> <polygon class="playertextboxbottom" points="188,50.7 0,0 376,0 "/> </svg>'
				//+'</div>'
				+'</div>'
				+'</div>'
			);
		};

		//needs to fire off with each lazyload call!!!!!
		//$( ".ac-img-container img" ).error(app.replace404);

		//run everything else after api call

		app.slickUse();
	},

	getData:function(path, callback){
		// console.log('I am here');
		$.when($.ajax({
			url: path,
			type: 'GET',
			// crossDomain: true,
			// dataType:'json',
		}).done(function(data) {
			// _data = data;
		}).fail(function() {
			console.log('Error from API - Please Try Again Later');
			//setTimeout 
			app.getAttendes();

		}).always(function() {
			//console.log('complete');
		}))
		// on AJAX finish
		.then(function(data){
			callback(data);
		});
	},

//////////////////////////////////////////////
//! app.scrollMagic();
//////////////////////////////////////////////
	scrollMagic: function(){
		
		var controller = new ScrollMagic.Controller();
		
		// ADD TO CONTROLLER
		var createScene = function createScene(variable, tween, triggerElem, triggerHk, duration, offSetVal, debug, debugname) {
			if (debug) {
				var variable = new ScrollMagic.Scene({triggerElement: triggerElem, triggerHook: triggerHk, duration: duration, offset: offSetVal})
					.addIndicators({name: (triggerElem + ' ' + debugname)})
					.setTween(tween)
					.addTo(controller);
				} else {
					var variable = new ScrollMagic.Scene({triggerElement: triggerElem, triggerHook: triggerHk, duration: duration, offset: offSetVal})
						.setTween(tween)
						.addTo(controller);
				}
			}
		
		// Parallax scenes and animations
		var navbarAnimVar, navbarAnim, p1, a1, p2, a2, p3, a3, p4, a4, p5, a5;
		
		// Create Animations

		// navbarAnime = new TimelineMax();
		// navbarAnime
		// 	.fromTo("#js-navbarlogo-anim", 1, {opacity: '100'}, {opacity: '0'}, 'a')
		// ;

		a1 = new TimelineMax();
		a1
			.fromTo("#p1l1", 1, {x: '-10%'}, {x: '10%'}, 'a')
			.fromTo("#p1l2", 1, {x: '10%'}, {x:'-10%'}, 'a')
			.fromTo("#p1l3", 1, {x: '-10%'}, {x:'10%'}, 'a')
		;
		
		a2 = new TimelineMax();
		a2
			.fromTo("#p2l1", 1, {x: '10%'}, {x: '-10%'}, 'a')
			.fromTo("#p2l2", 1, {x: '-10%'}, {x:'10%'}, 'a')
		;
		
		a3 = new TimelineMax();
		a3
			.fromTo("#p3l1", 1, {x: '-10%'}, {x: '10%'}, 'a')
			.fromTo("#p3l2", 1, {x: '8%'}, {x: '-2%'}, 'a')
			.fromTo("#p3l3", 1, {x: '-10%'}, {x: '10%'}, 'a')
			.fromTo("#p3l4", 1, {x: '-10%'}, {x: '10%'}, 'a')
		;
		
		a4 = new TimelineMax();
		a4
			.fromTo("#p4l1", 1, {x: '10%'}, {x: '-10%'}, 'a')
			.fromTo("#p4l2", 1, {x: '-2%'}, {x: '10%'}, 'a')
			.fromTo("#p4l3", 1, {x: '-2%'}, {x: '10%'}, 'a')
			.fromTo("#p4l4", 1, {x: '10%'}, {x: '-10%'}, 'a')
			// .fromTo("#p4l3", 1, {x: '-30%', y: '+0%'}, {x: '-10%', y: '+5%'}, 'a')
			// .fromTo("#p4l4", 1, {x: '-20%', y: '-5%'}, {x: '+15%', y: '+5%'}, 'a')
		;
		
		a5 = new TimelineMax();
		a5
			.fromTo("#p5l1", 1, {x: '10%'}, {x: '-10%'}, 'a')
			.fromTo("#p5l2", 1, {x: '-10%'}, {x: '10%'}, 'a')
			.fromTo("#p5l3", 1, {x: '10%'}, {x: '-10%'}, 'a')
		;
		
		//Create Scenes
		
		//				variable, tween, triggerElem, triggerHk, duration, offSetVal, debug, debugname
		// createScene(navbarAnimVar, navbarAnim, "#js-navbarlogo-anim", 1,  '100%', 0, false, 'logo');
		createScene(p1, a1, "#parallax-001", 1,  '200%', 0, false, 'p1');
		createScene(p2, a2, "#parallax-002", 1,  '200%', 0, false, 'p2');
		createScene(p3, a3, "#parallax-003", 1,  '200%', 0, false, 'p3');
		createScene(p4, a4, "#parallax-004", 1,  '200%', 0, false, 'p4');
		createScene(p5, a5, "#parallax-005", 1,  '200%', 0, false, 'p3');
		
	},
	
	newfunction:function() {
	
	},
	
	
	
};//END app();

//fades in the logo on navbar
$(window).scroll(function(){

	if ($(window).scrollTop() > $(window).height()-70) {
    	$(".nav-bar__logo").addClass("animateIN");
    } else {
    	$(".nav-bar__logo").removeClass("animateIN");
    }
});

//DOC READY
jQuery(document).ready(function() { 
	app.init();
});

//WINDOW RESIZE
$(window).resize(function() {
	
});